import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, Wrapper, Button, BMCButton, FragmentTagBtnGroup, Article } from '../components';
import PageProps from '../models/PageProps';
import { Edges } from '../models/AllMarkdownRemark';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import { media } from '../utils/media';
import rgba from 'polished/lib/color/rgba';
import darken from 'polished/lib/color/darken';
import lighten from 'polished/lib/color/lighten';

const Homepage = styled.main`
  display: flex;
  height: 100vh;
  flex-direction: row;
  @media ${media.tablet} {
    height: 100%;
    flex-direction: column;
  }
  @media ${media.phone} {
    height: 100%;
    flex-direction: column;
  }
`;

const GridRow: any = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${(props: any) => props.justifyContent || 'flex-start'};
  align-items: center;
  background: ${(props: any) =>
    props.background
      ? `linear-gradient(
      -185deg,
      ${rgba(darken(0.1, props.theme.colors.primary), 0)}, 
      ${rgba(lighten(0.1, props.theme.colors.grey.dark), 0.9)}), url(/assets/egypt.jpg) no-repeat`
      : null};
  background-size: cover;
  background-position: center;
  padding: 2rem 4rem;
  color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
  h1 {
    color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
  }
  @media ${media.tablet} {
    padding: 3rem 3rem;
    justify-content: center;
  }
  @media ${media.phone} {
    padding: 2rem 1.5rem;
  }
`;

const HomepageContent: any = styled.div`
  max-width: 30rem;
  text-align: ${(props: any) => (props.center ? 'center' : 'left')};
`;

const InfoSection: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    flex-shrink: 0;
  }
`;

const PointerH2: any = styled.h2<{ selected: boolean }>`
  cursor: pointer;
  display: inline-block;
  padding-right: 20px;
  &:hover {
    color: #ff4c4c;
  }
  text-decoration: ${props => props.selected && 'underline'};
`;

const IndexPage = ({ data }: PageProps) => {
  const { edges = [] } = data.allMarkdownRemark;
  const [selectedEdges, setSelectedEdges] = useState({
    edges: edges.filter(i => i.node.frontmatter.type === 'tech'),
    type: 'tech',
  });
  const [fragmentTag, setFragmentTag] = useState<'all' | 'life' | 'wife'>('all');
  useEffect(() => {
    if (fragmentTag === 'all') return;
    setSelectedEdges({ edges: edges.filter(i => i.node.frontmatter.tags.includes(fragmentTag)), type: selectedEdges.type });
    // if (selectedEdges.type === 'fragments') {
    //   const tags = [].concat.apply(
    //     [],
    //     // @ts-ignore
    //     selectedEdges.edges.map(edge => edge.node.frontmatter.tags),
    //   );
    //   setFragmentTags(Array.from(new Set(tags)));
    // } else {
    //   setFragmentTags([]);
    // }
  }, [fragmentTag]);
  return (
    <Layout>
      <Wrapper fullWidth={true}>
        <Helmet title={`Homepage | ${config.siteTitle}`} />
        <Homepage>
          <GridRow background={true} justifyContent="center">
            <HomepageContent center={true}>
              <h1>Huang, Shuo-Han (@ArvinHv)</h1>
              <p>I write about JavaScript, TypeScript, React, NodeJs, CSS, HTML and thoughts</p>
              <InfoSection>
                <Link to="/about">
                  <Button big={true}>
                    <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                    </svg>
                    About
                  </Button>
                </Link>
                <Link to="/now">
                  <Button big={true}>
                    <img src="/assets/live-icon.svg" />
                    Now
                  </Button>
                </Link>
              </InfoSection>
            </HomepageContent>
          </GridRow>
          <GridRow>
            <HomepageContent>
              <hr />
              <h2>Latest </h2>
              <section>
                <PointerH2
                  selected={selectedEdges.type === 'tech'}
                  onClick={() => setSelectedEdges({ edges: edges.filter(i => i.node.frontmatter.type === 'tech'), type: 'tech' })}
                >
                  Tech
                </PointerH2>
                <PointerH2
                  selected={selectedEdges.type === 'fragments'}
                  onClick={() => setSelectedEdges({ edges: edges.filter(i => i.node.frontmatter.type === 'fragments'), type: 'fragments' })}
                >
                  Fragments
                </PointerH2>
                {/* {selectedEdges.type === 'fragments' && <FragmentTagBtnGroup clickHandler={setFragmentTag} />} */}
              </section>
              {selectedEdges.edges.slice(0, 1).map((post: Edges) => (
                <Article
                  title={post.node.frontmatter.title}
                  date={post.node.frontmatter.date}
                  excerpt={post.node.excerpt}
                  timeToRead={post.node.timeToRead}
                  type={post.node.frontmatter.type}
                  slug={post.node.fields.slug}
                  key={post.node.fields.slug}
                />
              ))}
              <p className={'textRight'}>
                <Link to={`${selectedEdges.type}`}>All articles ({selectedEdges.edges.length})</Link>
              </p>
            </HomepageContent>
          </GridRow>
        </Homepage>
      </Wrapper>
      <BMCButton />
    </Layout>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM-DD-YYYY")
            tags
            type
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`;
